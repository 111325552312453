import React, { Fragment } from "react";
import { Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import queryString from "query-string";

const NotEligible = (props) => {
  const parsed = queryString.parse(props.location.search);
  const classes = {
    wrapper: {
      maxWidth: "400px",
      margin: "0 auto",
    },
    title: {
      fontWeight: "600 !important",
      fontSize: "24px !important",
      lineHeight: "29.26px !important",
      textAlign: "center",
      marginBottom: "16px !important",
    },
    bdyTxt: {
      marginBottom: "16px !important",
      fontWeight: "400 !important",
      fontSize: "16px !important",
      lineHeight: "20px !important",
      letterSpacing: "0.5 px !important",
      textAlign: "Center",
    },
    phnNum: {
      whiteSpace: "nowrap",
      fontWeight: 600,
    },
  };
  return (
    <Fragment>
      <Helmet>
        <title>Not Eligible</title>
      </Helmet>
      <Box component="div" sx={classes.wrapper}>
        <Typography sx={classes.title} variant="h5">
          Thanks for your response.
        </Typography>
        <Typography sx={classes.bdyTxt}>
          {" "}
          We’ll let your provider know your interest and they’ll reach out to
          schedule a visit. Or you can contact your provider directly{""}
          {localStorage.getItem("providerPhoneNumber") !== "null" ? (
            <>
              {" "}
              at
              <Box
                component="span"
                sx={classes.phnNum}
              >{` ${localStorage.getItem("providerPhoneNumber")}`}</Box>
            </>
          ) : (
            ""
          )}
          .
        </Typography>
      </Box>
    </Fragment>
  );
};
export default NotEligible;
