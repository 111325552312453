import { createTheme } from "@mui/material/styles";

export default function themeFile() {
  let customObject = {
    custom: {
      bgImage: "bg.png",
      logo: "bg.png",
    },
  };
  return createTheme(customObject);
}
