import { Analytics } from "aws-amplify";
let config;
let launchedFromSrc;
export const initialisePinPointConfig = (idpId, region, pinPointAppId) => {
  let pid = localStorage.getItem("patientId");
  config = {
    // To get the AWS Credentials, you need to configure
    // the Auth module with your Cognito Federated Identity Pool
    Auth: {
      identityPoolId: idpId,
      region: region,
    },
    Analytics: {
      autoSessionRecord: true,
      AWSPinpoint: {
        appId: pinPointAppId,
        region: region,
      },
      flushInterval: 10000,
      bufferSize: 10,
    },
  };
  if (pid) {
    config.Analytics.AWSPinpoint.endpoint = {
      userAttributes: {
        uID: [pid],
      },
    };
  }

  launchedFromSrc = localStorage.getItem("launchedFromSrc") || -1;
};

export const awsPinPointConfig = () => {
  return config;
};

export const sessionConfig = {
  enable: true,
  attributes: () => {
    return getAttributes();
  },
  getUrl: () => {
    return window.location.pathname;
  },
};

export const pageOptions = {
  enable: true,
  attributes: () => {
    return getAttributes();
  },
  type: "SPA",
  getUrl: () => {
    return window.location.pathname;
  },
};
const getAttributes = () => {
  let queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const src = parseInt(params.get("src"));
  if ((src && src == 1) || (!src && launchedFromSrc == 1)) {
    launchedFromSrc = 1;
    localStorage.setItem("launchedFromSrc", 1);
    return {
      app_type: "portal",
      launched_from: "email",
    };
  } else if ((src && src == 2) || (!src && launchedFromSrc == 2)) {
    launchedFromSrc = 2;
    localStorage.setItem("launchedFromSrc", 2);
    return {
      app_type: "portal",
      launched_from: "sms",
    };
  } else {
    return {
      app_type: "portal",
    };
  }
};
export const updatePinPointUserAttributes = async (userObj) => {
  if (userObj) {
    await Analytics.updateEndpoint({
      userAttributes: {
        uID: [userObj.patientId],
      },
    });
    localStorage.setItem("patientId", userObj.patientId);
    setTimeout(async () => {
      await Analytics.updateEndpoint({
        userAttributes: {
          uID: [userObj.patientId],
        },
      });
    }, 1000);
  } else {
    await Analytics.updateEndpoint({
      userAttributes: {
        uID: ["0"],
      },
    });
  }
};

export const updateGlassboxUserAttributes = async (patientId) => {
  const eventName = "LandingPage";
  let glassboxData = {
    sessionId: window._detector.sessionId,
    visitorId: window._detector.visitorId,
    message: eventName,
    userId: patientId,
  };
  if (window._detector) {
    window._detector.triggerCustomEventMap(eventName, glassboxData);
  }
};
