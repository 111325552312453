import React, { useEffect, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import DobVerificationUI from "./DobVerificationUI";
import { getStyleForAuthLayout } from "../styles/commonStyles";
import { useTheme, Box } from "@mui/material";
import { commonBackgroundImageHelper } from "../public/CommonAPIHelper";
import Loader from "../public/Loader";
const defaultLogo = require("../../assets/images/logo.png");

const DobVerification = (props) => {
  const theme = useTheme();
  const [dobBackgroundImage, setDobBackgroundImage] = useState();
  const [dobMobileBackgroundImage, setDobMobileBackgroundImage] = useState();
  const organizationId = localStorage.getItem("organizationId");
  const classes = getStyleForAuthLayout(
    theme,
    dobBackgroundImage,
    dobMobileBackgroundImage
  );
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(
    `${process.env.PUBLIC_URL}/org_assets/org-${organizationId}/logo.png`
  );
  useEffect(() => {
    (async () => {
      await commonBackgroundImageHelper(
        organizationId,
        setLoading,
        setDobBackgroundImage,
        setDobMobileBackgroundImage
      );
    })();
  }, []);
  return (
    <>
      {loading && <Loader />}
      <Box component="div" key="en" sx={classes.bgSet}>
        <div>
          <GridContainer justifyContent="center">
            <Box component="div" sx={classes.cardWidth}>
              <Box component="div" sx={classes.textCenter}>
                <Box
                  component="img"
                  alt=""
                  src={imageSrc}
                  sx={classes.logoSize}
                  onError={() => setImageSrc(defaultLogo)}
                />
              </Box>
              <DobVerificationUI />
            </Box>
          </GridContainer>
        </div>
      </Box>
    </>
  );
};
export default DobVerification;
