import React from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, useHistory } from "react-router";
import {
  updatePinPointUserAttributes,
  updateGlassboxUserAttributes,
} from "../../pinpointConfig";

function LandingPage(props) {
  const params = useParams();
  const history = useHistory();
  const getSiteDetails = async () => {
    try {
      const token = params.token;
      localStorage.setItem("token", token);
      const result = await axios({
        method: "get",
        url: "/api/cologuard/getSiteDetails",
        headers: { "x-access-token": props.match.params.token },
      });
      if (result.data.status === 1) {
        localStorage.setItem("statusDescription", "");
        let { patientId } = result.data;
        let organizationId = result.data.organizationId;
        localStorage.setItem("organizationId", organizationId);
        updatePinPointUserAttributes({ patientId });
        updateGlassboxUserAttributes(patientId);
        localStorage.setItem("siteName", result.data.siteName);
        if (result.data.providerPhoneNumber) {
          localStorage.setItem(
            "providerPhoneNumber",
            result.data.providerPhoneNumber
          );
        } else {
          localStorage.setItem("providerPhoneNumber", null);
        }
      }
    } catch (error) {
      let patientId =
        error?.response?.data?.patientId ?? localStorage.getItem("patientId");
      if (patientId) {
        updatePinPointUserAttributes({ patientId });
        updateGlassboxUserAttributes(patientId);
      }
      if (error.response.status === 401) {
        if (error.response.data.status === 0) {
          localStorage.setItem(
            "statusDescription",
            error.response.data.statusDescription
          );
        } else {
          localStorage.setItem("statusDescription", "");
        }
      }
    } finally {
      history.replace("/DobVerification");
    }
  };
  React.useEffect(() => {
    getSiteDetails();
  }, []);

  return (
    <div>
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      ></CircularProgress>
    </div>
  );
}
export default LandingPage;
