import React, { Suspense, Fragment, useEffect, useState } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Helmet } from "react-helmet";
import themeCalculator from "./ThemeFile";
import CircularProgress from "@mui/material/CircularProgress";
import Commonlayout from "./views/public/Commonlayout";
import PageNotFound from "./views/public/PageNotFound";
import Unsubscribe from "./views/public/SubsciptionUI/Unsubscribe";
import Resubscribe from "./views/public/SubsciptionUI/Resubscribe";
import axios from "axios";
function App(props) {
  const [favIconSrc, setFavIconSrc] = useState("");
  useEffect(() => {
    getSiteDetails();
  }, []);
  const getSiteDetails = async () => {
    let organizationId;
    try {
      let token = window.location.pathname;
      token = token.split("/");
      token = token.pop();
      const result = await axios({
        method: "get",
        url: "/api/cologuard/getSiteDetails",
        headers: { "x-access-token": token },
      });
      if (result.data.status === 1) {
        localStorage.setItem("statusDescription", "");
        organizationId = result.data.organizationId;
        localStorage.setItem("siteName", result.data.siteName);
      }
    } catch (error) {
      const unAuthCode = 401;
      if (error.response.status === unAuthCode) {
        organizationId = error.response.data.organizationId;
        if (error.response.data.status === 0) {
          localStorage.setItem(
            "statusDescription",
            error.response.data.statusDescription
          );
        } else {
          localStorage.setItem("statusDescription", "");
        }
      }
    } finally {
      localStorage.setItem("organizationId", organizationId);
      const orgFaviconImagePath = `${process.env.PUBLIC_URL}/org_assets/org-${organizationId}/favicon.ico`;
      setFavIconSrc(orgFaviconImagePath);
    }
  };
  useEffect(() => {
    if (favIconSrc.length) {
      const img = new Image();
      img.src = favIconSrc;
      img.onerror = () => {
        setFavIconSrc(`${process.env.PUBLIC_URL}/favicon.ico`);
      };
    }
  }, [favIconSrc]);
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Consent</title>
        <link rel="icon" type="image/png" href="" />
        <script
          id="_cls_detector"
          data-clsconfig={`reportURI=${props.glassboxReportingURL};`}
          src={`${process.env.PUBLIC_URL}/detector-dom-v1.min.js`}
          type="text/javascript"
        />
        <link
          id="favicon"
          rel="shortcut icon"
          href={favIconSrc}
          type="image/x-icon"
        />
      </Helmet>
      <ThemeProvider theme={themeCalculator()}>
        <CssBaseline />
        <Suspense
          fallback={
            <CircularProgress
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          }
        >
          <Router>
            <Switch>
              <Route
                path="/auth/:token"
                render={(props) => <Commonlayout {...props} />}
              />
              <Route
                path="/dobverification"
                render={(props) => <Commonlayout {...props} />}
              />
              <Route
                path="/questions"
                render={(props) => <Commonlayout {...props} />}
              />
              <Route
                path="/alreadydone"
                render={(props) => <Commonlayout {...props} />}
              />
              <Route
                path="/noteligible"
                render={(props) => <Commonlayout {...props} />}
              />
              <Route
                path="/schedulingredirect"
                render={(props) => <Commonlayout {...props} />}
              />
              <Route
                path="/consent"
                render={(props) => <Commonlayout {...props} />}
              />
              <Route
                path="/orderconfirmation"
                render={(props) => <Commonlayout {...props} />}
              />
              <Route
                path="/ordered"
                render={(props) => <Commonlayout {...props} />}
              />
              <Route
                path="/unsubscribe/:token"
                render={(props) => <Unsubscribe {...props} />}
              />
              <Route
                path="/resubscribe/:token"
                render={(props) => <Resubscribe {...props} />}
              />
              <Route path="*" render={(props) => <PageNotFound {...props} />} />
            </Switch>
          </Router>
        </Suspense>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
