import React, { useState } from "react";
import { Switch } from "react-router-dom";
import GridContainer from "../../components/Grid/GridContainer";
import OrderConfirmation from "../Consent/OrderConfirmation";
import QuestionOneUI from "../Questions/QuestionOneUI";
import QuestionTwoUI from "../Questions/QuestionTwoUI";
import NotEligible from "../NotEligible/NotEligible";
import Ordered from "../Consent/Ordered";
import { useTheme, Box } from "@mui/material";
import { getStyleForThankyouResponse } from "../styles/commonStyles";
import AlreadyDoneQuestionOneUI from "../AlreadyDoneQuestionnaire/AlreadyDoneQuestionOneUI";
import AlreadyDoneQuestionTwoUI from "../AlreadyDoneQuestionnaire/AlreadyDoneQuestionTwoUI";
import AlreadyDoneQuestionThreeUI from "../AlreadyDoneQuestionnaire/AlreadyDoneQuestionThreeUI";
const defaultLogo = require("../../assets/images/logo-dark.png");

function OrderLayout(props) {
  const theme = useTheme();
  const classes = getStyleForThankyouResponse(theme);
  const organizationId = localStorage.getItem("organizationId");
  const [imageSrc, setImageSrc] = useState(
    `${process.env.PUBLIC_URL}/org_assets/org-${organizationId}/logo-dark.png`
  );
  return (
    <>
      <Box component="div" key="en" sx={classes.commonBgSet}>
        <div>
          <GridContainer justifyContent="center">
            <Box component="div" sx={classes.cardWidth}>
              <Box component="div" sx={classes.textCenter}>
                <Box
                  component="img"
                  alt=""
                  src={imageSrc}
                  sx={classes.logoSize}
                  onError={() => setImageSrc(defaultLogo)}
                />
              </Box>
              <Switch>
                {props.location.pathname.includes("/questions/1") ? (
                  <>
                    <QuestionOneUI {...props} />
                  </>
                ) : (
                  ""
                )}
                {props.location.pathname.includes("/questions/2") ? (
                  <QuestionTwoUI {...props} />
                ) : (
                  ""
                )}
                {props.location.pathname.includes("/alreadydone/1") ? (
                  <AlreadyDoneQuestionOneUI {...props} />
                ) : (
                  ""
                )}
                {props.location.pathname.includes("/alreadydone/2") ? (
                  <AlreadyDoneQuestionTwoUI {...props} />
                ) : (
                  ""
                )}
                {props.location.pathname.includes("/alreadydone/3") ? (
                  <AlreadyDoneQuestionThreeUI {...props} />
                ) : (
                  ""
                )}
                {props.location.pathname.includes("/noteligible") ? (
                  <NotEligible {...props} />
                ) : (
                  ""
                )}
                {props.location.pathname.includes("/orderconfirmation") ? (
                  <OrderConfirmation {...props} />
                ) : (
                  ""
                )}
                {props.location.pathname.includes("/ordered") ? (
                  <Ordered {...props} />
                ) : (
                  ""
                )}
              </Switch>
            </Box>
          </GridContainer>
        </div>
      </Box>
    </>
  );
}
export default OrderLayout;
