import React, { Fragment, useState } from "react";
import { connect, Formik, Form } from "formik";
import {
  Button,
  CircularProgress,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import axios from "axios";
import ErrorDialog from "./InfoDialog";
import ErrorIcon from "../../assets/images/Error.png";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import queryString from "query-string";
import Loader from "../public/Loader";
const QuestionOneUI = (props) => {
  const theme = useTheme();
  const classes = {
    wrapper: {
      maxWidth: 600,
      margin: "0 auto",
    },
    title: {
      fontSize: "24px !important",
      fontWeight: "600 !important",
      color: "#0E1825",
      marginBottom: "5px !important",
      textAlign: "center",
    },
    subTiltle: {
      fontSize: "16px !important",
      color: "#0E1825",
      fontWeight: "600 !important",
      marginBottom: "15px !important",
    },
    step: {
      marginBottom: "8 !important",
      color: "#475970 !important",
      fontSize: "10px !important",
    },
    btnStyle: {
      width: "100%",
      border: "1px solid #39659A !important",
      fontSize: "14px !important",
      // color: "#39659A",
      borderRadius: "4 !important",
      marginBottom: "18px !important",
      "&:hover": {
        background: "transparent !important",
      },
      height: { xs: "auto", md: "60px" },
    },
    btnWrapper: {
      display: "inline-block",
      position: "relative",
      width: "100%",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    errBlock: {
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),linear-gradient(0deg, #F44336, #F44336)",
      color: "#621b16",
      padding: "8px 16px",
      borderRadius: 4,
      marginBottom: 5,
      display: "flex",
      alignItems: "center",
    },
    noPadXs: {
      [theme.breakpoints.down("md")]: {
        padding: "0 !important",
      },
    },
  };
  const [backgroundLoader, setBackgroundLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showError, setShowError] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [errorBodyText, setErrorBodyText] = useState("Something went wrong!");
  const errorTitle = "Error";
  const confirmTitle = "Are you sure?";
  const confirmBodyText =
    "Please confirm that you do not want an at-home screening kit.";
  const handleErrorDialogClose = () => {
    setError(false);
  };
  const handleConfirmDialogClose = () => {
    setShowConfirm(false);
  };
  const handleSubmit = () => {
    setShowConfirm(true);
  };
  const handleNotInterestedButton = () => {
    setBackgroundLoader(true);
    handleConfirm("opt out");
  };
  const handleAlreadyScreenedButton = async () => {
    setBackgroundLoader(true);
    let result = false;
    result = await handleConfirm("already done");
    if (result) {
      props.history.push("../alreadydone/1");
    }
  };
  const handleConfirm = async (consent) => {
    setLoading(true);
    setDisabled(true);
    setShowError("");
    let selectedQuestions = [
      { question: 1, answer: true },
      { question: 2, answer: "NA" },
    ];
    if (consent === "already done") {
      selectedQuestions[0]["answer"] = consent;
    } else if (consent === "opt out") {
      selectedQuestions[0]["answer"] = "not interested";
    }
    let dataToSend = {
      dob: `${localStorage.getItem("dob")}`,
      screeningAnswers: selectedQuestions,
      consent: consent,
    };
    try {
      let result = await axios({
        method: "post",
        url: "/api/cologuard/acceptconsent",
        headers: { "x-access-token": `${localStorage.getItem("token")}` },
        data: dataToSend,
      });
      if (
        result.data.status === 1 &&
        ["opt out", "already done"].includes(consent)
      ) {
        if (consent === "opt out") {
          props.history.push("/thankyouresponse");
        } else {
          return true;
        }
      } else if (result.data.status === 0) {
        let bookingUrlText =
          result.data.booking_url && result.data.booking_url.startsWith("http")
            ? result.data.booking_url
            : null;
        setLoading(false);
        setDisabled(false);
        setBackgroundLoader(false);
        localStorage.removeItem("dob");
        let searchString = queryString.stringify(
          { booking_url: bookingUrlText },
          { strict: false, skipNull: true }
        );
        props.history.push({
          pathname: "../noteligible/1",
          search: `?${searchString}`,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        if (error.response.data.status === 2) {
          props.history.push({
            pathname: "../ordered",
          });
        } else {
          setShowError(error.response.data.statusDescription);
          setLoading(false);
          setDisabled(false);
          setBackgroundLoader(false);
        }
      } else {
        setErrorBodyText("Something went wrong!");
        setLoading(false);
        setDisabled(false);
        setBackgroundLoader(false);
        setError(true);
      }
    }
  };
  const confirmbuttons = [
    <Button key="cancel" onClick={handleConfirmDialogClose} disabled={disabled}>
      Cancel
    </Button>,
    <Box key="confirm" component="div" sx={classes.btnWrapper}>
      <Button
        onClick={() => {
          handleConfirm("in office");
        }}
        disabled={disabled}
      >
        Confirm
      </Button>
    </Box>,
  ];
  const errorMsg = (
    <Box component="div" sx={classes.errBlock}>
      {" "}
      <img src={ErrorIcon} alt="error Img" />
      &nbsp;&nbsp;<span>{showError}</span>
    </Box>
  );
  const handleYesButtonClick = () => {
    props.history.push("./2");
  };
  return (
    <Fragment>
      {loading && <Loader />}
      <Formik initialValues={{ questions: [] }} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Box component="div" sx={classes.wrapper}>
              <Typography sx={classes.title}>
                You’re overdue for a colorectal screening
              </Typography>
              <br />
              <br />
              <Typography sx={classes.step}>1 OF 2</Typography>
              <Typography sx={classes.subTiltle}>
                Would you prefer to take your screening at home or discuss other
                options with your physician?
              </Typography>
              <br />
              {showError && (
                <Box component="div" sx={classes.errBlock}>
                  {" "}
                  <img src={ErrorIcon} alt="error Img" />
                  &nbsp;&nbsp;<span>{showError}</span>
                </Box>
              )}
              <GridContainer>
                <GridItem md={6} lg={6} xs={12} sm={12} sx={classes.noPadXs}>
                  <Button
                    sx={classes.btnStyle}
                    onClick={handleYesButtonClick}
                    disabled={loading}
                  >
                    At home
                  </Button>
                  <br />
                </GridItem>
                <GridItem md={6} lg={6} xs={12} sm={12} sx={classes.noPadXs}>
                  <Button
                    sx={classes.btnStyle}
                    type="submit"
                    disabled={loading}
                  >
                    DISCUSS OTHER OPTIONS
                  </Button>
                </GridItem>
                <GridItem md={6} lg={6} xs={12} sm={12} sx={classes.noPadXs}>
                  <Button
                    sx={classes.btnStyle}
                    disabled={loading}
                    onClick={handleAlreadyScreenedButton}
                  >
                    I’ve already been screened
                  </Button>
                </GridItem>
                <GridItem md={6} lg={6} xs={12} sm={12} sx={classes.noPadXs}>
                  <Button
                    sx={classes.btnStyle}
                    disabled={loading}
                    onClick={handleNotInterestedButton}
                  >
                    Not Interested
                  </Button>
                </GridItem>
              </GridContainer>
            </Box>
          </Form>
        )}
      </Formik>
      {showConfirm && (
        <ErrorDialog
          maxWidth="sm"
          title={confirmTitle}
          bodytext={confirmBodyText}
          errmsg={showError && errorMsg}
          successButton={confirmbuttons}
          handleClose={handleConfirmDialogClose}
        />
      )}
      {error && (
        <ErrorDialog
          maxWidth="sm"
          title={errorTitle}
          bodytext={errorBodyText}
          handleClose={handleErrorDialogClose}
        />
      )}
      {backgroundLoader && (
        <CircularProgress size={24} sx={classes.buttonProgress} />
      )}
    </Fragment>
  );
};
export default connect(QuestionOneUI);
