import React, { Fragment, useState } from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import { Button, useTheme, Box, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { Helmet } from "react-helmet";
import axios from "axios";
import ErrorDialog from "../Questions/InfoDialog";
import ErrorIcon from "../../assets/images/Error.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import thumps from "../../assets/images/thumps.png";
import Loader from "../public/Loader";
function Consent(props) {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState("");
  const [error, setError] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    setDisabled(true);
    setShowError("");
    try {
      let dataToSend = {
        consent: "opt in",
        dob: `${localStorage.getItem("dob")}`,
        screeningAnswers: [
          { question: 1, answer: false },
          { question: 2, answer: false },
        ],
      };
      let result = await axios({
        method: "post",
        url: "/api/cologuard/acceptconsent",
        headers: { "x-access-token": `${localStorage.getItem("token")}` },
        data: dataToSend,
      });
      if (result.data.status === 1) {
        setLoading(false);
        setDisabled(false);
        localStorage.removeItem("dob");
        props.history.push("./orderconfirmation");
      }
    } catch (error) {
      if (error.response.status === 401) {
        if (error.response.data.status === 0) {
          setShowError(error.response.data.statusDescription);
          setLoading(false);
          setDisabled(false);
        } else if (error.response.data.status === 2) {
          props.history.push("./ordered");
        }
      } else {
        setLoading(false);
        setDisabled(false);
        setError(true);
      }
    }
  };
  const errorTitle = "Error";
  const errorBodyText = "Something went wrong!";
  const handleErrorDialogClose = () => {
    setError(false);
  };
  const theme = useTheme();
  const classes = {
    submitBtn: {
      background: "#39659A !important",
      color: "#fff !important",
      padding: "13px 5px !important",
      width: "100%",
      "&:disabled": {
        background: "rgba(0, 0, 0, 0.12) !important",
        color: "color: rgba(0, 0, 0, 0.12) !important",
      },
    },
    title: {
      fontSize: "18px !important",
      color: "#0E1825",
      marginBottom: "8px !important",
      fontWeight: "600 !important",
      textAlign: "center",
    },
    cardStyle: {
      borderRadius: "8px",
      boxShadow: "4px 5px 20px rgba(25, 42, 85, 0.1)",
      marginBottom: "40px",
    },
    cardBodyStyle: { padding: "0.9375rem 2rem 0" },
    cardTxt: {
      color: "#152438",
      fontSize: "14px !important",
      marginBottom: "24px !important",
      textAlign: "center",
    },
    cardFooter: { padding: "0 2rem" },
    disclaimerTxt: {
      color: "#475970",
      fontSize: "13px !important",
      maxWidth: 400,
      margin: "0 auto 16px !important",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px !important",
      },
    },
    wrapper: {
      margin: theme.spacing(1),
      display: "inline-block",
      position: "relative",
      width: "100%",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    errBlock: {
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),linear-gradient(0deg, #F44336, #F44336)",
      color: "#621b16",
      padding: "8px 16px",
      borderRadius: "4px",
      marginBottom: 15,
      display: "flex",
      alignItems: "center",
    },
    noteWrap: { padding: "0 2rem 0.9375rem" },
    noteText: {
      background: "#F4F5F7",
      color: "#475970 !important",
      padding: "8px 16px",
      borderRadius: "4px",
      fontSize: "14px !important",
      margin: "8px !important",
      lineHeight: "1 !important",
      display: "flex",
      alignItems: "center",
    },
    noteInfoIcon: {
      fontSize: "18px !important",
      color: "#475970",
      marginRight: "10px",
      marginTop: "3px",
    },
    thumpsIcn: { textAlign: "center", marginTop: "16px" },
  };

  return (
    <Fragment>
      <Helmet>
        <title>Request Order</title>
      </Helmet>
      {loading && <Loader />}
      <Formik initialValues={{ requestOrder: false }} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Card sx={classes.cardStyle}>
              <Box component="div" sx={classes.thumpsIcn}>
                <Box component="img" src={thumps} alt="thumpsupImg" />
              </Box>
              <CardBody sx={classes.cardBodyStyle}>
                <Typography sx={classes.title}>
                  Great! You’re eligible.
                </Typography>
                <Typography sx={classes.cardTxt}>
                  Tap below to receive an at home colorectal screening.
                </Typography>
                {showError && (
                  <Box component="div" sx={classes.errBlock}>
                    {" "}
                    <Box component="img" src={ErrorIcon} alt="" />
                    &nbsp;&nbsp;<Box component="span">{showError}</Box>
                  </Box>
                )}
              </CardBody>
              <CardFooter
                sx={classes.cardFooter}
                style={{ justifyContent: "center" }}
              >
                <Box component="div" sx={classes.wrapper}>
                  <Button
                    type="submit"
                    size="small"
                    sx={classes.submitBtn}
                    disabled={disabled}
                  >
                    Request Screening
                  </Button>
                </Box>
              </CardFooter>
              <Box component="div" sx={classes.noteWrap}>
                <Typography sx={classes.noteText}>
                  <Box component="span">
                    <InfoOutlinedIcon sx={classes.noteInfoIcon} />
                  </Box>{" "}
                  <Box component="span">
                    Most insured patients pay $0<sup>*</sup>
                  </Box>
                </Typography>
              </Box>
            </Card>
            <Typography sx={classes.disclaimerTxt}>
              Estimate based on historical patient billing as of June 30, 2021.
              Rate of coverage varies by state and region. Exceptions for
              coverage may apply; only your insurer can confirm how the
              screening would be covered for you.
            </Typography>
            <br />
            <br />
          </Form>
        )}
      </Formik>
      {error && (
        <ErrorDialog
          maxWidth="sm"
          title={errorTitle}
          bodytext={errorBodyText}
          handleClose={handleErrorDialogClose}
        />
      )}
    </Fragment>
  );
}

export default Consent;
