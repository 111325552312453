import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import DobVerification from "../DobAuth/DobVerification";
import ConsentLayout from "../Consent/ConsentLayout";
import OrderLayout from "./OrderLayout";
import LandingPage from "../DobAuth/LandingPage";
import ThankyouResponse from "../ThankyouResponse/ThankyouResponse";
import SchedulingRedirect from "../NotEligible/SchedulingRedirect";
function Commonlayout(props) {
  return (
    <Router>
      <Switch>
        <Route
          path="/auth/:token"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/dobverification"
          render={(props) => <DobVerification {...props} />}
        />
        <Route
          path="/questions/1"
          render={(props) => <OrderLayout {...props} />}
        />
        <Route
          path="/questions/2"
          render={(props) => <OrderLayout {...props} />}
        />
        <Route
          path="/alreadydone/1"
          render={(props) => <OrderLayout {...props} />}
        />
        <Route
          path="/alreadydone/2"
          render={(props) => <OrderLayout {...props} />}
        />
        <Route
          path="/alreadydone/3"
          render={(props) => <OrderLayout {...props} />}
        />
        <Route
          path="/noteligible/:questionnumber"
          render={(props) => <OrderLayout {...props} />}
        />
        <Route
          path="/schedulingredirect"
          render={(props) => <SchedulingRedirect {...props} />}
        />
        <Route
          path="/consent"
          render={(props) => <ConsentLayout {...props} />}
        />
        <Route
          path="/orderconfirmation"
          render={(props) => <OrderLayout {...props} />}
        />
        <Route path="/ordered" render={(props) => <OrderLayout {...props} />} />
        <Route
          path="/thankyouresponse"
          render={(props) => <ThankyouResponse {...props} />}
        />
      </Switch>
    </Router>
  );
}
export default Commonlayout;
