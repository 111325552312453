import React, { Fragment, useState } from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import GridContainer from "../../components/Grid/GridContainer";
import { getStyleForThankyouResponse } from "../styles/commonStyles";
const defaultLogo = require("../../assets/images/logo-dark.png");

const ThankyouResponse = () => {
  const theme = useTheme();
  const classes = getStyleForThankyouResponse(theme);

  const organizationId = localStorage.getItem("organizationId");
  const [imageSrc, setImageSrc] = useState(
    `${process.env.PUBLIC_URL}/org_assets/org-${organizationId}/logo-dark.png`
  );
  return (
    <Fragment>
      <Helmet>
        <title>Thankyou Response</title>
      </Helmet>
      <Box component="div" key="en" sx={classes.commonBgSet}>
        <GridContainer justifyContent="center">
          <Box component="div" sx={classes.cardWidth}>
            <Box component="div" sx={classes.textCenter}>
              <Box
                component="img"
                alt=""
                src={imageSrc}
                sx={classes.logoSize}
                onError={() => setImageSrc(defaultLogo)}
              />
            </Box>
            <Box component="div" sx={classes.wrapper}>
              <Typography sx={classes.title} variant="h5">
                Thanks for your response.
              </Typography>
              <Typography sx={classes.bdyTxt}>
                We’ll let your provider know your reply and have them update
                their system.
              </Typography>
            </Box>
          </Box>
        </GridContainer>
      </Box>
    </Fragment>
  );
};
export default ThankyouResponse;
