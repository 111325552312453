import React, { useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import { useTheme, Box, Typography } from "@mui/material";
import { getStyleForAuthLayout } from "../styles/commonStyles";
const defaultLogo = require("../../assets/images/logo.png");

const DobVerification = (props) => {
  const organizationId = localStorage.getItem("organizationId");
  const [imageSrc, setImageSrc] = useState(
    `${process.env.PUBLIC_URL}/org_assets/org-${organizationId}/logo.png`
  );
  const theme = useTheme();
  const classes = getStyleForAuthLayout(theme);
  return (
    <Box component="div" key="en" sx={classes.bgSet}>
      <div>
        <GridContainer justifyContent="center">
          <Box component="div" sx={classes.cardWidth}>
            <Box component="div" sx={classes.textCenter}>
              <Box
                component="img"
                alt=""
                src={imageSrc}
                sx={classes.logoSize}
                onError={() => setImageSrc(defaultLogo)}
              />
            </Box>
            <>
              <Card sx={classes.cardStyle}>
                <CardBody>
                  <Typography sx={classes.title}>
                    You must have received a personalized message.You can use
                    the application using that url from that message.
                  </Typography>
                </CardBody>
              </Card>
            </>
          </Box>
        </GridContainer>
      </div>
    </Box>
  );
};
export default DobVerification;
