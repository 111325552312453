let getCommonLayoutStyle = (theme, backgroundImage, backgroundImageMobile) => {
  if (backgroundImage == null) {
    backgroundImage = require("../../assets/images/bg.png");
    backgroundImageMobile = require("../../assets/images/mobileBg.png");
  }
  return {
    bgSet: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      paddingTop: "9%",
      backgroundColor: "#fff",
      backgroundPosition: "top center",
      [theme.breakpoints.down("sm")]: {
        backgroundImage: `url(${backgroundImageMobile})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      },
    },
    cardWidth: {
      maxWidth: "552px",
      [theme.breakpoints.down("md")]: {
        maxWidth: 343,
      },
      "@media (max-width: 320px)": {
        maxWidth: 260,
      },
    },
    textCenter: {
      textAlign: "center",
    },
    logoSize: {
      width: "300px",
      marginBottom: "41px",
      "@media (max-width: 320px)": {
        width: "240px",
      },
    },
  };
};

let getCommonOrderStyle = (theme) => {
  return {
    wrapper: {
      maxWidth: "400px",
      margin: "0 auto",
    },
    title: {
      fontWeight: "600 !important",
      fontSize: "24px !important",
      lineHeight: "29.26px !important",
      textAlign: "center",
      marginBottom: "16px !important",
    },
    subTiltle: {
      fontSize: "16px !important",
      color: "#0E1825",
      fontWeight: "600 !important",
      marginBottom: "15px !important",
    },
    subTitle2: {
      fontSize: "16px !important",
      color: "#0E1825",
      fontWeight: "600 !important",
    },
    bdyTxt: {
      marginBottom: "16px !important",
      fontWeight: "400 !important",
      fontSize: "16px !important",
      lineHeight: "20px !important",
      letterSpacing: "0.5 px !important",
      textAlign: "Center",
    },
    phnNum: {
      fontWeight: 600,
    },
    commonBgSet: {
      backgroundRepeat: "repeat-x",
      paddingTop: "5%",
      backgroundColor: "#fff",
      backgroundPosition: "top center",
    },
    cardWidth: {
      maxWidth: "552px",
      [theme.breakpoints.down("md")]: {
        maxWidth: 343,
      },
    },
    textCenter: {
      textAlign: "center",
    },
    logoSize: {
      width: "200px",
      marginBottom: "41px",
      [theme.breakpoints.down("md")]: {
        width: "128px",
        marginBottom: "20px",
      },
    },
    back: {
      // color: "#39659A",
      fontSize: "14px !important",
      fontWeight: "500!important",
      padding: "0 !important",
      "&:hover": {
        background: "transparent !important",
      },
    },
    btnStyle: {
      width: "100%",
      border: "1px solid #39659A80 !important",
      fontSize: "14px !important",
      // color: "#39659A",
      borderRadius: "4 !important",
      marginBottom: "18px !important",
      "&:hover": {
        background: "transparent !important",
      },
      "&:disabled": {
        border: "1px solid #0000001F !important",
      },
      height: { xs: "auto", md: "60px" },
    },
  };
};

const getStyleForConsentLayout = (
  theme,
  backgroundImage = null,
  backgroundImageMobile = null
) => {
  let commonStyleObject = getCommonLayoutStyle(
    theme,
    backgroundImage,
    backgroundImageMobile
  );
  return commonStyleObject;
};

const getStyleForThankyouResponse = (theme) => {
  let commonStyleObject = getCommonOrderStyle(theme);
  return commonStyleObject;
};
const getStyleForAuthLayout = (
  theme,
  backgroundImage = null,
  backgroundImageMobile = null
) => {
  let commonStyleObject = getCommonLayoutStyle(
    theme,
    backgroundImage,
    backgroundImageMobile
  );
  return commonStyleObject;
};
const getStyleForUnsubscribe = (theme) => {
  let commonStyleObject = getCommonLayoutStyle(theme);
  commonStyleObject.buttonWrapper = {
    margin: "8px 0",
    display: "inline-block",
    position: "relative",
  };
  commonStyleObject.submitBtn = {
    background: "#39659A !important",
    color: "#fff !important",
    padding: "13px 25px !important",
    "&:disabled": {
      background: "rgba(0, 0, 0, 0.12) !important",
      color: "color: rgba(0, 0, 0, 0.12) !important",
    },
  };
  commonStyleObject.contentWrapper = {
    maxWidth: 550,
    margin: "0 auto",
    padding: "0 23px",
  };
  commonStyleObject.title = {
    fontSize: "24px !important",
    fontWeight: "600 !important",
    marginBottom: "8px !important",
    color: "#0E1825",
  };
  commonStyleObject.bodyTxt = {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    marginBottom: "24px !important",
    color: "#0E1825",
  };
  commonStyleObject.logoSize = { width: "100%", marginBottom: "41px" };
  commonStyleObject.errBlock = {
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),linear-gradient(0deg, #F44336, #F44336)",
    color: "#621b16",
    padding: "8px 16px",
    borderRadius: 4,
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    maxWidth: "550px",
    margin: "0 auto",
  };
  commonStyleObject.buttonProgress = {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  };

  return commonStyleObject;
};

const getStyleForLoader = (theme) => {
  return {
    commonLoader: {
      position: "fixed",
      width: "100vw",
      height: "100vh",
      top: 0,
      left: 0,
      zIndex: 10000,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
    },
    bgImageLoader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "196px",
    },
    logoSize: { width: "100%", marginBottom: "41px" },
  };
};

export {
  getStyleForConsentLayout,
  getStyleForAuthLayout,
  getStyleForUnsubscribe,
  getStyleForThankyouResponse,
  getStyleForLoader,
};
