import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Helmet } from "react-helmet";
import { Typography, Link, useTheme, Box, List, ListItem } from "@mui/material";
import order from "../../assets/images/Vector.png";
import thumbs from "../../assets/images/thumps.png";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import cologaurdLogo from "../../assets/images/cologuardLogo.png";
import cologuardOrder from "../../assets/images/cologuard-order.png";

function Ordered() {
  const theme = useTheme();
  const classes = {
    mainGrid: {
      background: "#3685e60f",
      alignItems: "center",
      padding: "15px",
    },
    noPadXs: {
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
    },
    shippingTxt: {
      // color: "#3685e6",
      fontSize: "14px !important",
    },
    thanksShippingtxt: {
      // color: "#3685e6",
      fontSize: "18px !important",
      fontWeight: "600 !important",
      marginBottom: "16px !important",
    },
    cardStyle: {
      marginTop: "16px",
      borderRadius: "8px",
      boxShadow: "4px 5px 20px rgba(25, 42, 85, 0.1)",
      marginBottom: "40px",
    },
    cardBodyStyle: {
      borderTop: "8px solid #3685E6",
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      padding: "0.9375rem 2rem",
    },
    cardTxt: {
      color: "#475970",
      fontSize: "13px !important",
      marginBottom: "29px !important",
      marginTop: "12px !important",
      textAlign: "center",
    },
    cardSubHeading: {
      color: "#475970",
      fontWeight: "600 !important",
      fontSize: "13px !important",
    },
    cardList: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "29px !important",
      marginTop: "12px !important",
    },
    cardItem: {
      padding: 0,
    },
    logoTxt: {
      maxWidth: 400,
      margin: "0 auto 40px !important",
      fontWeight: "500 !important",
      fontSize: "20px !important",
      textAlign: "center",
      lineHeight: "21.94px !important",
      letterSpacing: "0.15px !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px !important",
      },
    },
    logo: {
      width: "165px",
    },
    textCenter: {
      textAlign: "center",
    },
    learnMoreWrap: {
      textAlign: "right",
      marginBottom: "20px",
      "& a": {
        textDecoration: "none",
        color: "#39659A",
        cursor: "pointer",
        fontSize: 14,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Previously Ordered</title>
      </Helmet>
      <GridContainer sx={classes.mainGrid}>
        <GridContainer>
          <Typography sx={classes.thanksShippingtxt}>
            According to your medical records, you are due for colon cancer
            screening. When caught in early stages, colon cancer is more
            treatable.
          </Typography>
        </GridContainer>
        <GridItem xs={1} sm={1} md={1} lg={1} sx={classes.noPadXs}>
          <img src={order} alt="delivery truck icon" />
        </GridItem>
        <GridItem xs={11} sm={11} md={11} lg={11} sx={classes.textRight}>
          <Typography sx={classes.shippingTxt}>
            A health care provider has started the process of ordering Cologuard
            <sup>®</sup> for you
          </Typography>
        </GridItem>
      </GridContainer>
      <Card sx={classes.cardStyle}>
        <CardBody sx={classes.cardBodyStyle}>
          <Box component="div" sx={classes.learnMoreWrap}>
            <Link
              sx={classes.learnMoreLink}
              href={"https://www.cologuard.com/"}
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </Link>
          </Box>
          <GridContainer>
            <GridItem xs={12} sx={classes.textCenter}>
              <img src={cologuardOrder} alt="" />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} sx={classes.textCenter}>
              <Box
                component="img"
                src={cologaurdLogo}
                alt=""
                sx={classes.logo}
              />
            </GridItem>
          </GridContainer>
          <Typography sx={classes.cardTxt}>
            It's a colon cancer screening test that will arrive at your home
            soon. Cologuard is an easy-to-use, noninvasive test. You collect a
            sample in your own home and send it back for testing.
          </Typography>
          <Typography sx={classes.cardSubHeading}>Please note:</Typography>
          <Typography sx={{ ...classes.cardTxt, textAlign: "left" }}>
            If your health has changed since you were last screened, you may no
            longer be at average risk for colon cancer. Cologuard is only
            intended for people who are 45 years or older at average risk of
            getting colon cancer. If you have had adenomas, inflammatory bowel
            disease, certain hereditary syndromes, a history of colorectal
            cancer or a previous positive Cologuard test or colonoscopy, you are
            no longer eligible to screen with Cologuard and should contact your
            primary care provider.
          </Typography>
          <Typography sx={classes.cardSubHeading}>
            Here is what to expect next:
          </Typography>
          <List sx={classes.cardList}>
            <StepsListItem
              text={"Your Cologuard kit will arrive in a white box."}
            />
            <StepsListItem
              text={"Follow the instructions inside. No prep is required."}
            />
            <StepsListItem
              text={
                "Return the kit via UPS® prepaid shipping. You’ll use the same box it arrived in."
              }
            />
            <StepsListItem
              text={"Your result should be available within two weeks."}
            />
          </List>
          <Typography sx={classes.cardSubHeading}>
            Want to view a how-to-use video and get help returning your
            Cologuard Kit?
          </Typography>
          <Typography sx={classes.cardTxt}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.cologuard.com/using-and-returning-your-cologuard-kit?utm_campaign=use&amp;utm_source=redirect&amp;wvideo=baoqbt1v5u"
            >
              <img
                src="https://embed-ssl.wistia.com/deliveries/b317b112a555666a4e06fb37aa938aa1.jpg?image_play_button_size=2x&amp;image_crop_resized=960x537&amp;image_play_button=1&amp;image_play_button_color=54bbffe0"
                alt="How to Use Cologuard: Instructions | Cologuard® Patient Site"
                style={{ width: "100%" }}
              />
            </a>
          </Typography>
          <Typography sx={classes.cardSubHeading}>
            Have any questions?
          </Typography>
          <Typography sx={{ ...classes.cardTxt, textAlign: "left" }}>
            Contact the Exact Sciences Laboratories Customer Care team at
            <b> 1-844-870-8870</b>. Team members are available 24 hours a day, 7
            days a week. Or chat with a member of the Exact Sciences
            Laboratories customer care team at{" "}
            <Link href="https://Cologuard.com">Cologuard.com</Link>.
          </Typography>
        </CardBody>
      </Card>
    </>
  );
}

function StepsListItem({ text }) {
  const classes = {
    stepItem: {
      display: "flex",
      padding: 0,
    },
    itemTxt: {
      color: "#475970",
      fontSize: "13px !important",
      marginBottom: "10px !important",
      marginTop: "10px !important",
    },
  };
  return (
    <ListItem sx={classes.stepItem}>
      <img
        src={thumbs}
        alt="point"
        style={{
          width: "25px",
          alignSelf: "flex-start",
          margin: "10px 10px 10px 0",
        }}
      />
      <Typography sx={classes.itemTxt}>{text}</Typography>
    </ListItem>
  );
}

export default Ordered;
