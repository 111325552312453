import React, { Fragment, useState } from "react";
import { Typography } from "@mui/material";
import { connect } from "formik";
import { Button } from "@mui/material";
import Loader from "../public/Loader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Formik, Form } from "formik";
import axios from "axios";
import ErrorDialog from "./InfoDialog";
import ErrorIcon from "../../assets/images/Error.png";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { useTheme, Box } from "@mui/material";

const QuestionTwoUI = (props) => {
  const theme = useTheme();
  const classes = {
    wrapper: {
      maxWidth: 600,
      margin: "0 auto",
    },
    title: {
      fontSize: "24px !important",
      fontWeight: "600 !important",
      color: "#0E1825",
      marginBottom: "5px !important",
      textAlign: "center",
    },
    noPadXs: {
      [theme.breakpoints.down("md")]: {
        padding: "0 !important",
      },
    },
    subTiltle: {
      fontSize: "16px !important",
      color: "#0E1825",
      fontWeight: "600 !important",
      marginBottom: "15px !important",
    },
    bodyTxt: {
      marginBottom: "8px !important",
      fontSize: "14px !important",
      color: "#152438",
    },
    step: {
      marginBottom: "8 !important",
      color: "#475970 !important",
      fontSize: "10px !important",
    },
    btnStyle: {
      width: "100%",
      border: "1px solid #39659A !important",
      fontSize: "14px !important",
      // color: "#39659A",
      borderRadius: "4 !important",
      marginBottom: "18px !important",
      "&:hover": {
        background: "transparent !important",
      },
      height: { xs: "auto", md: "60px" },
    },
    back: {
      // color: "#39659A",
      fontSize: "14px !important",
      fontWeight: "500!important",
      padding: "0 !important",
      "&:hover": {
        background: "transparent !important",
      },
    },
    errBlock: {
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),linear-gradient(0deg, #F44336, #F44336)",
      color: "#621b16",
      padding: "8px 16px",
      borderRadius: 4,
      marginBottom: 15,
      display: "flex",
      alignItems: "center",
    },
    btnWrapper: {
      display: "inline-block",
      position: "relative",
      width: "100%",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -21,
      marginLeft: -12,
    },
  };
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState("");
  const [error, setError] = useState(false);
  const errorTitle = "Error";
  const errorBodyText = "Something went wrong!";
  const handleErrorDialogClose = () => {
    setError(false);
  };
  const handleSubmit = async () => {
    setLoading(true);
    setDisabled(true);
    setShowError("");
    let selectedQuestions = [
      { question: 1, answer: false },
      { question: 2, answer: true },
    ];
    let dataToSend = {
      dob: `${localStorage.getItem("dob")}`,
      screeningAnswers: selectedQuestions,
      consent: "ineligible",
    };
    try {
      let result = await axios({
        method: "post",
        url: "/api/cologuard/acceptconsent",
        headers: { "x-access-token": `${localStorage.getItem("token")}` },
        data: dataToSend,
      });
      if (result.data.status === 0) {
        setLoading(false);
        setDisabled(false);
        localStorage.removeItem("dob");
        props.history.push("../noteligible/2");
      }
    } catch (error) {
      if (error.response.status === 401) {
        if (error.response.data.status === 0) {
          setShowError(error.response.data.statusDescription);
          setLoading(false);
          setDisabled(false);
        } else if (error.response.data.status === 2) {
          props.history.push("../ordered");
        }
      } else {
        setLoading(false);
        setDisabled(false);
        setError(true);
      }
    }
  };
  const handleBackClick = () => {
    props.history.push("./1");
  };
  const handleNoButtonClick = () => {
    props.history.push("../consent");
  };
  return (
    <Fragment>
      {loading && <Loader />}
      <Formik initialValues={{ questions: [] }} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Box component="div" sx={classes.wrapper}>
              <Button sx={classes.back} onClick={handleBackClick}>
                <ArrowBackIcon /> &nbsp;Back
              </Button>
              <br />
              <br />
              <Typography sx={classes.step}>2 OF 2</Typography>
              <Typography sx={classes.subTiltle}>
                Is there anything that may put you at a higher risk of colon
                cancer, such as a family history of colon cancer or a personal
                history of:
              </Typography>
              <Typography sx={classes.bodyTxt}>
                Colon cancer or certain types of polyps
              </Typography>
              <Typography sx={classes.bodyTxt}>
                Ulcerative colitis or Crohn’s disease
              </Typography>
              <Typography sx={classes.bodyTxt}>
                Getting radiation to the abdomen (belly) or pelvic area to treat
                a prior cancer
              </Typography>
              <Typography sx={classes.bodyTxt}>
                A confirmed or suspected hereditary colon cancer syndrome, such
                as familial adenomatous polyposis (FAP) or Lynch syndrome
                (hereditary non-polyposis colon cancer or HNPCC)
              </Typography>
              <br />
              {showError && (
                <Box component="div" sx={classes.errBlock}>
                  {" "}
                  <Box component="img" src={ErrorIcon} alt="error Img" />
                  &nbsp;&nbsp;<span>{showError}</span>
                </Box>
              )}
              <GridContainer>
                <GridItem md={6} lg={6} xs={12} sm={12} sx={classes.noPadXs}>
                  <Box component="div" sx={classes.btnWrapper}>
                    <Button
                      sx={classes.btnStyle}
                      type="submit"
                      disabled={disabled}
                    >
                      Yes
                    </Button>
                  </Box>
                  <br />
                </GridItem>
                <GridItem md={6} lg={6} xs={12} sm={12} sx={classes.noPadXs}>
                  <Button
                    sx={classes.btnStyle}
                    onClick={handleNoButtonClick}
                    disabled={disabled}
                  >
                    No
                  </Button>
                </GridItem>
              </GridContainer>
            </Box>
          </Form>
        )}
      </Formik>
      {error && (
        <ErrorDialog
          maxWidth="sm"
          title={errorTitle}
          bodytext={errorBodyText}
          handleClose={handleErrorDialogClose}
        />
      )}
    </Fragment>
  );
};
export default connect(QuestionTwoUI);
