import axios from "axios";
const defaultUnsubscriptionBackgroungImage = require("../../assets/images/unsubscription.png");
const defaultBackgroungImage = require("../../assets/images/bg.png");
const defaultBackgroungImageMobile = require("../../assets/images/mobileBg.png");

const getSiteDetails = async (token) => {
  try {
    localStorage.setItem("token", token);
    const result = await axios({
      method: "get",
      url: "/api/cologuard/getSiteDetails",
      headers: { "x-access-token": token },
    });
    if (result.data.status === 1) {
      localStorage.setItem("statusDescription", "");
      let organizationId = result.data.organizationId;
      localStorage.setItem("organizationId", organizationId);
      localStorage.setItem("siteName", result.data.siteName);
      const unsubscriptionImagePath = `${process.env.PUBLIC_URL}/org_assets/org-${organizationId}/unsubscription.png`;
      return unsubscriptionImagePath;
    }
  } catch (error) {
    const unAuthCode = 401;
    if (error.response.status === unAuthCode) {
      if (error.response.data.status === 0) {
        localStorage.setItem(
          "statusDescription",
          error.response.data.statusDescription
        );
      } else {
        localStorage.setItem("statusDescription", "");
      }
    }
    return defaultUnsubscriptionBackgroungImage;
  }
};

const checkImageFileExist = async (imagePath) => {
  let finalImage = false;
  try {
    let result = await axios({
      method: "get",
      url: imagePath,
    });
    if (result.status === 200) {
      if (result.headers["content-type"].includes("image")) {
        finalImage = imagePath;
      }
    }
  } catch (error) {
    console.log("File Does not exist");
  }
  return finalImage;
};

const checkBackgroundImageExist = async (organizationId) => {
  const orgBackgroungImagePath = `${process.env.PUBLIC_URL}/org_assets/org-${organizationId}/bg.png`;
  const orgBackgroungImagePathMobile = `${process.env.PUBLIC_URL}/org_assets/org-${organizationId}/mobileBg.png`;
  let obj = {
    desktop: defaultBackgroungImage,
    mobile: defaultBackgroungImageMobile,
  };
  let bgExist = await checkImageFileExist(orgBackgroungImagePath);
  if (bgExist) {
    obj["desktop"] = bgExist;
  }
  let bgMobileExist = await checkImageFileExist(orgBackgroungImagePathMobile);
  if (bgMobileExist) {
    obj["mobile"] = bgMobileExist;
  }
  return obj;
};

const commonBackgroundImageHelper = async (
  organizationId,
  setLoading,
  setDobBackgroundImage,
  setDobMobileBackgroundImage
) => {
  let dobBackgroundImages = await checkBackgroundImageExist(organizationId);
  setLoading(false);
  setDobBackgroundImage(dobBackgroundImages.desktop);
  setDobMobileBackgroundImage(dobBackgroundImages.mobile);
};

const handleStoreAlreadyDoneDetails = async (body) => {
  let flag = false;
  try {
    let result = await axios({
      method: "post",
      url: "/api/cologuard/storeAlreadyDoneDetails",
      headers: { "x-access-token": `${localStorage.getItem("token")}` },
      data: { questions: body },
    });
    if (result.data.status === 1) {
      flag = true;
    }
  } catch (error) {
    flag = false;
  }
  return flag;
};

export {
  getSiteDetails,
  checkBackgroundImageExist,
  commonBackgroundImageHelper,
  handleStoreAlreadyDoneDetails,
};
