import React, { useState } from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import { getStyleForUnsubscribe } from "../../styles/commonStyles";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import ErrorIcon from "../../../assets/images/Error.png";
import axios from "axios";
import { useTheme, Box } from "@mui/material";
import BackgroundImage from "./BackgroundImage";
import Loader from "../Loader";

const Resubscribe = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [loading, ShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(null);
  const theme = useTheme();
  const classes = getStyleForUnsubscribe(theme);
  const token = props.match.params.token;

  const handleResubscribe = async () => {
    setDisabled(true);
    ShowLoading(true);
    setShowError(false);
    setShowErrorMsg(null);
    try {
      let reSubscribe = await axios({
        method: "post",
        url: "/api/cologuard/unsubscribe",
        headers: { "x-access-token": token },
      });
      if (reSubscribe.status === 200 && reSubscribe.data.status === 1) {
        setDisabled(false);
        ShowLoading(false);
        localStorage.setItem("siteName", reSubscribe.data.siteName);
        props.history.push("../unsubscribe/" + token, "unSubscribed");
      }
    } catch (error) {
      if (
        error.response.data.status === 0 &&
        (error.response.status === 400 || error.response.status === 401)
      ) {
        setShowError(true);
        setShowErrorMsg(error.response.data.statusDescription);
        setDisabled(false);
        ShowLoading(false);
      } else if (
        error.response.data.status === 2 &&
        error.response.status === 401
      ) {
        props.history.push("../ordered");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div key="en">
          <div>
            <GridContainer>
              <div style={{ width: "100%" }}>
                <BackgroundImage token={token} />
                <Box component="div" sx={classes.contentWrapper}>
                  <Typography sx={classes.title}>
                    Resubscribe successful
                  </Typography>
                  <Typography sx={classes.bodyTxt}>
                    You’ve been re-subcribed to receiving screening
                    recommendation outreach from{" "}
                    {localStorage.getItem("siteName")}. Welcome back!
                  </Typography>
                  <Typography sx={classes.bodyTxt}>
                    If you change your mind, you can unsubscribe at:
                  </Typography>
                  <Box component="div" sx={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      size="small"
                      sx={classes.submitBtn}
                      disabled={disabled}
                      onClick={handleResubscribe}
                    >
                      unsubscribe
                    </Button>
                  </Box>
                </Box>
                {showError && (
                  <Box component="div" sx={classes.errBlock}>
                    {" "}
                    <img src={ErrorIcon} alt="" />
                    &nbsp;&nbsp;<span>{showErrorMsg}</span>
                  </Box>
                )}
              </div>
            </GridContainer>
          </div>
        </div>
      )}
    </>
  );
};
export default Resubscribe;
