import React, { useState, useEffect } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import Consent from "./Consent";
import { getStyleForConsentLayout } from "../styles/commonStyles";
import { useTheme, Box } from "@mui/material";
import { commonBackgroundImageHelper } from "../public/CommonAPIHelper";
import Loader from "../public/Loader";
const defaultLogo = require("../../assets/images/logo.png");

const ConsentLayout = (props) => {
  const organizationId = localStorage.getItem("organizationId");
  const [imageSrc, setImageSrc] = useState(
    `${process.env.PUBLIC_URL}/org_assets/org-${organizationId}/logo.png`
  );
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [backgroundImage, setBackgroundImage] = useState();
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState();
  const classes = getStyleForConsentLayout(
    theme,
    backgroundImage,
    mobileBackgroundImage
  );
  useEffect(() => {
    (async () => {
      await commonBackgroundImageHelper(
        organizationId,
        setLoading,
        setBackgroundImage,
        setMobileBackgroundImage
      );
    })();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box component="div" key="en" sx={classes.bgSet}>
          <div>
            <GridContainer justifyContent="center">
              <Box component="div" sx={classes.cardWidth}>
                <Box component="div" sx={classes.textCenter}>
                  <Box
                    component="img"
                    alt=""
                    src={imageSrc}
                    sx={classes.logoSize}
                    onError={() => setImageSrc(defaultLogo)}
                  />
                </Box>
                <Consent loading={loading} setLoading={setLoading} {...props} />
              </Box>
            </GridContainer>
          </div>
        </Box>
      )}
    </>
  );
};
export default ConsentLayout;
