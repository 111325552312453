import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

export function CustomSelect({ field, _form, options, label, ...props }) {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        label={label}
        MenuProps={{
          sx: {
            height: "min(400px,90%)",
          },
        }}
        {...field}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
