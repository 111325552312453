import React, { Fragment, useEffect, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Helmet } from "react-helmet";
import { Typography, Link, useTheme, Box } from "@mui/material";
import order from "../../assets/images/Vector.png";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import cologaurdLogo from "../../assets/images/cologuardLogo.png";
import cologaurdOrder from "../../assets/images/cologuard-order.png";

function OrderConfirmation(props) {
  const theme = useTheme();
  const classes = {
    mainGrid: {
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),linear-gradient(0deg, #4CAF50, #4CAF50)",
      alignItems: "center",
      padding: "15px",
    },
    noPadXs: {
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
    },
    shippingTxt: {
      color: "#4CAF50",
      fontSize: "14px !important",
    },
    thanksShippingtxt: {
      color: "#1e4620",
      fontSize: "18px !important",
      fontWeight: "600 !important",
      marginBottom: "16px !important",
    },
    cardStyle: {
      marginTop: "16px",
      borderRadius: "8px",
      boxShadow: "4px 5px 20px rgba(25, 42, 85, 0.1)",
      marginBottom: "40px",
    },
    cardBodyStyle: {
      borderTop: "8px solid #3685E6",
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      padding: "0.9375rem 2rem",
    },
    cardTxt: {
      color: "#475970",
      fontSize: "13px !important",
      marginBottom: "29px !important",
      marginTop: "12px !important",
      textAlign: "center",
    },
    logoTxt: {
      maxWidth: 400,
      margin: "0 auto 40px !important",
      fontWeight: "500 !important",
      fontSize: "20px !important",
      textAlign: "center",
      lineHeight: "21.94px !important",
      letterSpacing: "0.15px !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px !important",
      },
    },
    logo: {
      width: "165px",
    },
    disclaimerTxt: {
      color: "#475970",
      fontSize: "13px !important",
      maxWidth: 400,
      margin: "0 auto 16px !important",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px !important",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    learnMoreWrap: {
      textAlign: "right",
      marginBottom: "20px",
      "& a": {
        textDecoration: "none",
        color: "#39659A",
        cursor: "pointer",
        fontSize: 14,
      },
    },
  };

  const [programType, setProgramType] = useState(null);
  useEffect(() => {
    setProgramType(localStorage.getItem("programType"));
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Order Confirmed</title>
      </Helmet>
      <GridContainer sx={classes.mainGrid}>
        <GridContainer>
          <Typography sx={classes.thanksShippingtxt}>
            Thank you! Your request has been successfully sent.{" "}
          </Typography>
        </GridContainer>
        <GridItem xs={1} sm={1} md={1} lg={1} sx={classes.noPadXs}>
          <img src={order} alt="" />
        </GridItem>
        <GridItem xs={11} sm={11} md={11} lg={11} sx={classes.textRight}>
          <Typography sx={classes.shippingTxt}>
            You can expect your colorectal cancer screening to ship in 1-2 days.
          </Typography>
        </GridItem>
      </GridContainer>
      {(programType === "1" || programType === "2") && (
        <>
          <Card sx={classes.cardStyle}>
            <CardBody sx={classes.cardBodyStyle}>
              <Box component="div" sx={classes.learnMoreWrap}>
                <Link
                  sx={classes.learnMoreLink}
                  href={"https://www.cologuard.com/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </Link>
              </Box>
              <GridContainer>
                <GridItem xs={12} sx={classes.textCenter}>
                  <img src={cologaurdOrder} alt="" />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={classes.textCenter}
                >
                  <Box
                    component="img"
                    src={cologaurdLogo}
                    alt=""
                    sx={classes.logo}
                  />
                </GridItem>
              </GridContainer>
              <Typography sx={classes.cardTxt}>
                An effective and noninvasive screening option for adults 45 and
                older at average risk for colon cancer.
              </Typography>
            </CardBody>
          </Card>
          <Typography sx={classes.disclaimerTxt}>
            Cologuard® is intended to screen adults 45 years of age and older
            who are at average risk for colorectal cancer by detecting certain
            DNA markers and blood in the stool. Do not use if you have adenomas,
            have inflammatory bowel disease and certain hereditary syndromes, or
            a personal or family history of colorectal cancer. Cologuard® is not
            a replacement for colonoscopy in high risk patients. Cologuard®
            performance in adults ages 45-49 is estimated based on a large
            clinical study of patients 50 and older.
          </Typography>
        </>
      )}
      <br />
      <br />
    </Fragment>
  );
}

export default OrderConfirmation;
