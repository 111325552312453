import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import { Amplify, Analytics } from "aws-amplify";
import {
  awsPinPointConfig,
  sessionConfig,
  pageOptions,
  initialisePinPointConfig,
} from "./pinpointConfig";
const root = ReactDOM.createRoot(document.getElementById("root"));
let getConfig = async () => {
  let result = await axios({
    method: "get",
    url: "/api/getconfig",
  });
  initialisePinPointConfig(
    result.data.pinpoint.idpId,
    result.data.pinpoint.region,
    result.data.pinpoint.appId
  );
  Amplify.configure(awsPinPointConfig()["Auth"]);
  Amplify.configure(awsPinPointConfig()["Analytics"]);
  Analytics.autoTrack("session", sessionConfig);
  Analytics.autoTrack("pageView", pageOptions);
  return result.data;
};

getConfig().then(function (configObject) {
  root.render(
    <App glassboxReportingURL={configObject.glassbox.reportingUrl} />
  );
});
