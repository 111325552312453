import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { withRouter } from "react-router";
import queryString from "query-string";
function SchedulingRedirect(props) {
  function ScheduleTimeout() {
    const parsed = queryString.parse(props.location.search);
    window.location.replace(parsed.booking_url);
  }
  useEffect(() => {
    setTimeout(ScheduleTimeout, 3000);
  });
  return (
    <CircularProgress
      style={{ position: "absolute", top: "50%", left: "50%" }}
    ></CircularProgress>
  );
}
export default withRouter(SchedulingRedirect);
