import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { Button, useTheme, Typography, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useHistory } from "react-router-dom";
import { handleStoreAlreadyDoneDetails } from "../public/CommonAPIHelper";
import Loader from "../public/Loader";
import { getStyleForThankyouResponse } from "../styles/commonStyles";

const AlreadyDoneQuestionThreeUI = () => {
  const theme = useTheme();
  const maxCharacterLength = 500;
  const classes = getStyleForThankyouResponse(theme);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [helperText, setHelperText] = useState("");
  const [showError, setShowError] = useState(false);

  const handleNextClick = async () => {
    let selectedOption = location.state["selectedOption"];
    let date = location.state["date"];
    let data = {
      [`${selectedOption}`]: {
        note: inputText.trim() === "" ? null : inputText.trim(),
        date,
      },
    };
    setLoading(true);
    await handleStoreAlreadyDoneDetails(data);
    setLoading(false);
    history.push("/thankyouresponse");
  };

  const handleInput = (event) => {
    let input = event.target.value;
    setInputText(input);
    let length = input.length;
    if (length > maxCharacterLength) {
      setHelperText(`Max character limit is ${maxCharacterLength}`);
      setShowError(true);
    } else {
      setHelperText("");
      setShowError(false);
    }
  };

  const handleBackToAlreadyDoneQuestionTwoUI = () => {
    let selectedOption = location.state["selectedOption"];

    let details = {
      selectedOption,
    };
    history.push({ pathname: "./2", state: details });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Formik initialValues={{ questions: [] }} onSubmit={handleNextClick}>
        {() => (
          <Form>
            <Stack sx={classes.wrapper} gap={"24px"} alignItems="start">
              <Button
                sx={classes.back}
                onClick={handleBackToAlreadyDoneQuestionTwoUI}
              >
                <ArrowBackIcon /> &nbsp;Back
              </Button>
              <Stack alignItems="start" gap={1}>
                <Typography sx={classes.subTitle2}>
                  Where was your prior screening performed and what was the
                  result?
                </Typography>
                <Typography
                  color="#152438"
                  variant="caption"
                  align="center"
                  fontWeight={500}
                >
                  This question is optional
                </Typography>
              </Stack>
              <TextField
                error={showError}
                id="outlined-basic"
                label="Prior Screening Details"
                variant="outlined"
                inputProps={{ maxLength: maxCharacterLength + 1 }}
                onInput={handleInput}
                helperText={helperText}
                rows={5}
                fullWidth
                multiline
              />
              <Button type="submit" sx={classes.btnStyle} disabled={showError}>
                Submit
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};
export default AlreadyDoneQuestionThreeUI;
