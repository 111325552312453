import React, { Fragment, useState } from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import axios from "axios";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import { object, date } from "yup";
import { Helmet } from "react-helmet";
import { Typography, useTheme, Box } from "@mui/material";
import { withRouter } from "react-router";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import ErrorIcon from "../../assets/images/Error.png";
import Loader from "../public/Loader";
import ErrorDialog from "../Questions/InfoDialog";
import { updatePinPointUserAttributes } from "../../pinpointConfig";

function DobVerificationUI(props) {
  const theme = useTheme();
  const classes = {
    title: {
      fontSize: "24px !important",
      color: "#0E1825",
      fontWeight: "600 !important",
      textAlign: "center",
      marginBottom: "12px !important",
      "@media (max-width: 320px)": {
        fontSize: "20px !important",
      },
    },
    subTitle: {
      fontSize: "17px !important",
      color: "#152438",
      textAlign: "center",
      maxWidth: "488px",
      margin: "0 auto",
      marginBottom: "25px !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px !important",
      },
    },
    cardStyle: {
      borderRadius: "8px",
      "& label": {
        "@media (max-width: 320px)": {
          fontSize: "13px !important",
        },
      },
    },
    submitBtn: {
      background: "#39659A !important",
      color: "#fff !important",
      width: "100%",
    },
    errBlock: {
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),linear-gradient(0deg, #F44336, #F44336)",
      color: "#621b16",
      padding: "8px 16px",
      borderRadius: "4px",
      marginBottom: "15px",
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      margin: theme.spacing(1),
      display: "inline-block",
      position: "relative",
      width: "100%",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  };

  const [showError, setShowError] = useState(
    localStorage.getItem("statusDescription")
  );
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const yupSchema = object().shape({
    date: date()
      .test("", "Invalid Date of Birth", (value) => {
        let year = new Date(value).getFullYear();
        let selectedDate = new Date(value);
        return !(year < 1900 || selectedDate > new Date());
      })
      .required("Invalid Date of Birth")
      .typeError("Invalid Date of Birth"),
  });
  const handleSubmit = async (values) => {
    setLoading(true);
    setShowError("");
    let date = new Date(values.date);
    let year = `${date.getFullYear()}`;
    let day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let fullDate = year + "-" + month + "-" + day;
    try {
      let result = await axios({
        method: "post",
        url: "/api/cologuard/validatedob",
        headers: { "x-access-token": localStorage.getItem("token") },
        data: { dob: fullDate },
      });
      if (result.data.status === 1) {
        updatePinPointUserAttributes({ patientId: result.data.patientId });
        setLoading(false);
        localStorage.setItem("dob", fullDate);
        localStorage.setItem("programType", result.data.programType);
        props.history.push("../questions/1");
      }
    } catch (error) {
      let patientId =
        error?.response?.data?.patientId ?? localStorage.getItem("patientId");
      if (patientId) {
        updatePinPointUserAttributes({ patientId });
      }
      if (error.response.status === 401) {
        if (error.response.data.status === 0) {
          setShowError(error.response.data.statusDescription);
          setLoading(false);
        } else if (error.response.data.status == 2) {
          props.history.push("../ordered");
        }
      } else {
        setLoading(false);
        setError(true);
      }
    }
  };
  const errorTitle = "Error";
  const errorBodyText = "Something went wrong!";
  const handleErrorDialogClose = () => {
    setError(false);
  };
  return (
    <Fragment>
      <Helmet>
        <title>Consent Verification</title>
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{ date: null }}
          onSubmit={handleSubmit}
          validationSchema={yupSchema}
        >
          {(props) => (
            <Form>
              <Card sx={classes.cardStyle}>
                <CardBody>
                  <Typography sx={classes.title}>
                    Confirm Your Identity
                  </Typography>
                  <Typography sx={classes.subTitle}>
                    Confirm your date of birth to view your recommended
                    screening.
                  </Typography>
                  {showError && (
                    <Box component="div" sx={classes.errBlock}>
                      {" "}
                      <img src={ErrorIcon} alt="error Img" />
                      &nbsp;&nbsp;<span>{showError}</span>
                    </Box>
                  )}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      onChange={(value) =>
                        props.setFieldValue("date", value, true)
                      }
                      label="Date of Birth (MM/DD/YYYY)"
                      inputFormat="MM/dd/yyyy"
                      openTo="year"
                      views={["year", "month", "day"]}
                      inputProps={{ placeholder: "Date of Birth (MM/DD/YYYY)" }}
                      onClose={() => props.setFieldTouched("date")}
                      value={props.values.date}
                      displayErrorMessage={false}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={props.touched.date && props.errors.date}
                          helperText={props.touched.date && props.errors.date}
                          onBlur={() => props.setFieldTouched("date")}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </CardBody>
                <CardFooter
                  sx={classes.cardFooter}
                  style={{ justifyContent: "center" }}
                >
                  <Box component="div" sx={classes.wrapper}>
                    <Button type="submit" size="small" sx={classes.submitBtn}>
                      Confirm
                    </Button>
                  </Box>
                </CardFooter>
                {error && (
                  <ErrorDialog
                    maxWidth="sm"
                    title={errorTitle}
                    bodytext={errorBodyText}
                    handleClose={handleErrorDialogClose}
                  />
                )}
              </Card>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  );
}

export default withRouter(DobVerificationUI);
