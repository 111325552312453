import { CircularProgress } from "@mui/material";
import { useTheme, Box } from "@mui/material";
import { getStyleForLoader } from "../styles/commonStyles";
function Loader(props) {
  const theme = useTheme();
  const classes = getStyleForLoader(theme);
  return (
    <Box component="div" sx={classes.commonLoader}>
      <CircularProgress />
    </Box>
  );
}

export default Loader;
