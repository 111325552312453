import React, { useState, useEffect } from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import { getStyleForUnsubscribe } from "../../styles/commonStyles";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import ErrorIcon from "../../../assets/images/Error.png";
import axios from "axios";
import { useTheme, Box } from "@mui/material";
import BackgroundImage from "./BackgroundImage";
import Loader from "../Loader";

const Unsubscribe = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [pageloading, setPageLoading] = useState(true);
  const [unsubscribeContent, setUnsubscribeContent] = useState(false);
  const theme = useTheme();
  const classes = getStyleForUnsubscribe(theme);
  const token = props.match.params.token;

  useEffect(() => {
    if (props.location.state === "unSubscribed") {
      setPageLoading(false);
      setUnsubscribeContent(true);
    }
    const unsubscribe = async () => {
      try {
        let result = await axios({
          method: "post",
          url: "/api/cologuard/unsubscribe",
          headers: { "x-access-token": token },
        });
        if (result.status === 200 && result.data.status === 1) {
          setPageLoading(false);
          localStorage.setItem("siteName", result.data.siteName);
          setUnsubscribeContent(true);
        }
      } catch (error) {
        if (
          error.response.data.status === 0 &&
          (error.response.status === 400 || error.response.status === 401)
        ) {
          setError(true);
          setPageLoading(false);
          setErrorMsg(error.response.data.statusDescription);
          setDisabled(false);
          setUnsubscribeContent(false);
        } else if (
          error.response.data.status === 2 &&
          error.response.status === 401
        ) {
          props.history.push("../ordered");
        }
      }
    };
    if (props.location.state !== "unSubscribed") {
      unsubscribe();
    }
  }, [props.location.state, token]);

  const handleSubmit = async () => {
    setDisabled(true);
    setPageLoading(true);
    setError(false);
    setErrorMsg(null);
    try {
      let result = await axios({
        method: "post",
        url: "/api/cologuard/resubscribe",
        headers: { "x-access-token": token },
      });
      if (result.status === 200 && result.data.status === 1) {
        setUnsubscribeContent(true);
        setError(false);
        setErrorMsg(null);
        setPageLoading(false);
        setDisabled(false);
        props.history.push("../resubscribe/" + token);
      }
    } catch (error) {
      if (
        error.response.data.status === 0 &&
        (error.response.status === 400 || error.response.status === 401)
      ) {
        setUnsubscribeContent(true);
        setError(true);
        setErrorMsg(error.response.data.statusDescription);
        setDisabled(false);
        setPageLoading(false);
      } else if (
        error.response.data.status === 2 &&
        error.response.status === 401
      ) {
        props.history.push("../ordered");
      }
    }
  };
  return (
    <>
      {pageloading ? (
        <Loader />
      ) : (
        <div key="en">
          <div>
            <GridContainer>
              <div style={{ width: "100%" }}>
                <BackgroundImage token={token} />
                {unsubscribeContent && (
                  <Box component="div" sx={classes.contentWrapper}>
                    <Typography sx={classes.title}>
                      Unsubscribe successful.
                    </Typography>
                    <Typography sx={classes.bodyTxt}>
                      You will no longer receive screening recommendation
                      outreach from {localStorage.getItem("siteName")}. We’re
                      sorry to see you go.
                    </Typography>
                    <Typography sx={classes.bodyTxt}>
                      If you change your mind, you can re-subscribe at:
                    </Typography>
                    <Box component="div" sx={classes.buttonWrapper}>
                      <Button
                        type="submit"
                        size="small"
                        sx={classes.submitBtn}
                        disabled={disabled}
                        onClick={handleSubmit}
                      >
                        Re-subscribe
                      </Button>
                    </Box>
                  </Box>
                )}
                {error && (
                  <Box component="div" sx={classes.errBlock}>
                    {" "}
                    <img src={ErrorIcon} alt="" />
                    &nbsp;&nbsp;<span>{errorMsg}</span>
                  </Box>
                )}
              </div>
            </GridContainer>
          </div>
        </div>
      )}
    </>
  );
};
export default Unsubscribe;
