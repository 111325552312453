import React from "react";
// @material-ui/core components
import Grid from "@mui/material/Grid";

const grid = {
  position: "relative",
  width: "100%",
  minHeight: "1px",
  paddingRight: "15px",
  paddingLeft: "15px",
  flexBasis: "auto",
};

function GridItem({ ...props }) {
  const { sx, children, className, ...rest } = props;
  return (
    <Grid item {...rest} sx={[grid, ...(Array.isArray(sx) ? sx : [sx])]}>
      {children}
    </Grid>
  );
}

export default GridItem;
