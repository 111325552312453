import React, { Fragment, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Button, useTheme, Typography, Stack } from "@mui/material";
import * as yup from "yup";
import { handleStoreAlreadyDoneDetails } from "../public/CommonAPIHelper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../public/Loader";
import { getStyleForThankyouResponse } from "../styles/commonStyles";
import { CustomSelect } from "../../components/FormikValidatedComponents/CustomSelect";

const AlreadyDoneQuestionTwoUI = () => {
  const theme = useTheme();
  const classes = getStyleForThankyouResponse(theme);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const yearOptions = React.useMemo(() => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 50 }, (_, i) => ({
      value: currentYear - i,
      label: currentYear - i,
    }));
  }, []);

  const monthOptions = React.useMemo(
    () => [
      { value: "01-01", label: "January" },
      { value: "02-01", label: "February" },
      { value: "03-01", label: "March" },
      { value: "04-01", label: "April" },
      { value: "05-01", label: "May" },
      { value: "06-01", label: "June" },
      { value: "07-01", label: "July" },
      { value: "08-01", label: "August" },
      { value: "09-01", label: "September" },
      { value: "10-01", label: "October" },
      { value: "11-01", label: "November" },
      { value: "12-01", label: "December" },
    ],
    []
  );

  const handleSubmit = async (values) => {
    let year = values.year;
    let month = values.month ? values.month : monthOptions[0].value;
    const date = `${year}-${month}`;
    let selectedOption = location.state["selectedOption"];
    let payload = {
      [`${selectedOption}`]: {
        note: null,
        date,
      },
    };
    let details = { selectedOption, date };
    setLoading(true);
    let api_result = await handleStoreAlreadyDoneDetails(payload);
    setLoading(false);
    if (api_result) {
      history.push({ pathname: "/alreadydone/3", state: details });
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Formik
        initialValues={{ year: "", month: "" }}
        validationSchema={yup.object({
          year: yup.string().required(),
          month: yup.string(),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <Stack sx={classes.wrapper} gap={"24px"} alignItems="start">
              <Button
                sx={classes.back}
                onClick={() => {
                  history.push("./1");
                }}
              >
                <ArrowBackIcon /> &nbsp;Back
              </Button>
              <Typography sx={classes.subTitle2}>
                Approximately when was your prior screening?
              </Typography>
              <Field
                name="year"
                as={CustomSelect}
                label="Year"
                options={yearOptions}
              />
              <Field
                name="month"
                as={CustomSelect}
                label="Month (Optional)"
                options={monthOptions}
              />
              <Button
                type="submit"
                sx={classes.btnStyle}
                disabled={loading || !!formikProps.errors.year}
              >
                Next
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default AlreadyDoneQuestionTwoUI;
