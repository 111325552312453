import React from "react";
import Dialog from "@mui/material/Dialog";
import { Typography, Divider, DialogActions, useTheme } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const CustomDialogTitle = (props) => {
  const { title, handleClose } = props;
  const theme = useTheme();
  const classes = {
    root: {
      marginBottom: "10px !important",
    },
    closeButton: {
      position: "absolute !important",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#626467 !important",
    },
    title: {
      fontWeight: "600 !important",
      fontSize: "17px !important",
      marginRight: "9px !important",
    },
  };
  return (
    <DialogTitle sx={classes.root}>
      <Typography sx={classes.title}>{title}</Typography>
      {handleClose ? (
        <IconButton
          aria-label="Close"
          sx={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const InfoDialog = (props) => {
  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="responsive-dialog-title"
        onClose={props.handleClose}
        fullWidth={props.maxWidth && true}
        maxWidth={props.maxWidth ? props.maxWidth : "xs"}
      >
        <CustomDialogTitle
          title={props.title}
          handleClose={props.handleClose}
        />
        <DialogContent>
          {props.bodytext}
          {props.errmsg}
        </DialogContent>
        <Divider />
        {props.successButton && (
          <DialogActions>
            {props.successButton.map((button, i) => (
              <div key={i}>
                <div>{button}</div>
              </div>
            ))}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
export default InfoDialog;
