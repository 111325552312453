import React, { Fragment, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { Button, useTheme, Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loader from "../public/Loader";
import { useHistory } from "react-router-dom";
import { handleStoreAlreadyDoneDetails } from "../public/CommonAPIHelper";
import { getStyleForThankyouResponse } from "../styles/commonStyles";
import { CustomRadio } from "../../components/FormikValidatedComponents/CustomRadio";
const AlreadyDoneQuestionOneUI = () => {
  const theme = useTheme();
  const classes = getStyleForThankyouResponse(theme);

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const screeningOptions = React.useMemo(() => {
    return [
      { value: "colonoscopy", label: "Colonscopy" },
      { value: "colonography", label: "CT Colonography (Virtual colonoscopy)" },
      { value: "sigmoidoscopy", label: "Flexible Sigmoidoscopy" },
      { value: "cologuard", label: "Cologuard" },
      { value: "fobt", label: "Fecal Occult Blood Test [FOBT]" },
    ];
  }, []);

  const handleSubmit = async (values) => {
    let body = {
      [`${values.selectedOption}`]: { date: null, note: null },
    };
    let details = {
      selectedOption: values.selectedOption,
    };
    setLoading(true);
    let result = await handleStoreAlreadyDoneDetails(body);
    setLoading(false);
    if (result) {
      history.push({ pathname: "/alreadydone/2", state: details });
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <Formik
        initialValues={{ selectedOption: "" }}
        validationSchema={yup.object({
          selectedOption: yup.string().required(),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <Box component="div" sx={classes.wrapper}>
              <Button
                sx={classes.back}
                onClick={() => history.push("../questions/1")}
              >
                <ArrowBackIcon /> &nbsp;Back
              </Button>
              <br />
              <br />
              <Typography sx={classes.subTitle2}>
                What is the most recent screening you had performed?
              </Typography>
              <br />
              <Field
                name="selectedOption"
                label="screening options"
                options={screeningOptions}
                component={CustomRadio}
              />
              <br />
              <br />
              <Button
                type="submit"
                sx={classes.btnStyle}
                disabled={loading || !!formikProps.errors.selectedOption}
              >
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};
export default AlreadyDoneQuestionOneUI;
