import React, { useState, useEffect } from "react";
import { getSiteDetails } from "../CommonAPIHelper";
import { getStyleForLoader } from "../../styles/commonStyles";
import { CircularProgress, useTheme, Box } from "@mui/material";

const defaultUnsubscriptionBackgroungImage = require("../../../assets/images/unsubscription.png");

const BackgroundImage = (props) => {
  const [imageSrc, setImageSrc] = useState("");
  const theme = useTheme();
  const [initialLoader, setInitialLoader] = useState(true);

  const classes = getStyleForLoader(theme);
  useEffect(() => {
    (async () => {
      let backgroundImage = await getSiteDetails(props.token);
      setImageSrc(backgroundImage);
      setInitialLoader(false);
    })();
  }, []);

  return (
    <>
      {initialLoader ? (
        <Box sx={classes.bgImageLoader}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component="div" sx={{ minHeight: "196px" }}>
          <img
            alt=""
            src={imageSrc}
            sx={classes.logoSize}
            width="100%"
            onError={() => {
              setImageSrc(defaultUnsubscriptionBackgroungImage);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default BackgroundImage;
